.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282c34;
  color: white;
  padding: 10px 20px;
  height: auto;
}

.footer-column {
  flex: 1;
  text-align: center;
  padding: 0 10px;
}

.footer-column h3 {
  margin: 5px 0;
}

.footer-column ul {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 0;
  margin: 5px 0;
  list-style: none;
}

.footerNav {
  color: white;
  text-decoration: none;
  margin: 5px 0;
}

.footerNav:hover {
  transition: color 0.3s;
  color: #32CD32;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 5px 0;
}

.social-icons a {
  color: white;
  transition: color 0.3s;
}

#github:hover {
  color: #6E5494;
  transition: color 0.3s;
}

#linkedin:hover {
  color: #258dbf;
  transition: color 0.3s;
}