/* Header styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: #303030;
  color: white;
  position: relative;
  z-index: 1000;
}

.logo a {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.navDesktop {
  display: flex;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 30px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  padding: 5px 0;
}

.navDesktop a.active{
  color: #32CD32;
}

.nav-links a::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #32CD32;
  transition: width 0.3s;
}

.nav-links a:hover::before {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .navDesktop {
    display: none;
  }
  .header {
    display: flex;
    height: 3vh;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: #303030;
    color: white;
    position: relative;
    z-index: 1000;
  }
  
  .logo a {
    color: white;
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
    transition: all 0.15s ease;
  }
  
  .nav-mobile {
    display: flex;
    flex-direction: column;
    position: fixed;
    list-style: none;
    background-color: #303030;
    top: calc(3vh + 15px);
    right: 0;
    width: 70%;
    height: calc(100vh - 3vh - 30px);
    text-align: center;
    overflow-y: auto;
  }  

  .nav-links-mobile li {
    margin: 10px 0;
    list-style: none;
  }
  
  .nav-links-mobile a {
    color: white;
    text-decoration: none;
    font-size: 2rem;
  }

  .divider hr {
    width: 90%;
  }  
}

@media screen and (min-width: 769px) {
  .menu-icon, .nav-mobile {
    display: none;
  }
}