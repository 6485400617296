html, body, #root, .App {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .App {
    display: flex;
    flex-direction: column;
  }
  
  main {
    flex: 1;
  }  