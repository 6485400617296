.photoErwin img {
    box-shadow: 0.5rem 0.5rem #303030, -0.5rem -0.5rem #ccc;
}

@media screen and (max-width: 768px) {
    .introduction {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 2vh;
    }

    .section {
        width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1vh 0;
    }

    .section hr {
        height: 2px;
        background-color: #303030;
    }

    .photoErwin img {
        width: 100%;
        height: auto;
    }

    .aboutMe {
        width: 100%;
        text-align: center;
    }

    .languagesAndFrameworks {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
    }

    .languagesAndFrameworks div {
        margin: 1vh 0;
        text-align: center;
    }
}

@media screen and (min-width: 769px) {
    .introduction {
        display: flex;
        margin-top: 3vh;
    }
    
    .section {
        width: 40vw;
        margin: 0 5vw;
    }
    
    .section hr {
        height: 2px;
        background-color: #303030;
    }
    
    .section:first-of-type {
        margin-right: 5vw;
    }
    
    .section:last-of-type {
        margin-left: 5vw; 
    }
    
    .languagesAndFrameworks {
        display: flex;
        justify-content: space-evenly;
    }
    
    .introduction img {
        width: 75%;
    }
    
    .photoErwin {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}